import { Outlet } from "react-router-dom";

import { AuthStateProvider } from "../../context/AuthState";

export default function AuthPage() {
  return (
    <>
      <AuthStateProvider>
        <Outlet />
      </AuthStateProvider>
    </>
  );
}
