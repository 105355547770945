import React from "react";
import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";

import AiBrainIcon from "../../../assets/AiBrainIcon.svg";
import { useAuthState } from "../../../context/AuthState";

const ConfirmEmail: React.FC = () => {
  const { emailConirmationState } = useAuthState();

  const navigate = useNavigate();

  if (!emailConirmationState.email) {
    console.debug("No email found");
    navigate("/auth/login");
  }

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              // className="mx-auto h-10 w-auto"
              className="mx-auto h-10 w-auto rounded-md bg-primary-100"
              src={AiBrainIcon}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Confirm Your Email
            </h2>
          </div>

          {emailConirmationState.isLoading ? (
            <div className="flex items-center justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : (
            <VerificationDiv />
          )}

          {emailConirmationState.isResendingEmail ? (
            <div className="flex items-center justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : (
            <ResendConfirmationEmailDiv />
          )}

          <p className="text-center text-sm leading-6 text-gray-500">
            <Link
              to="/auth/login"
              className="font-semibold text-gray-600 hover:text-gray-500"
            >
              Back to Login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

const VerificationDiv: React.FC = () => {
  const { emailConirmationState, handleConfirmationCodeChange } =
    useAuthState();
  return (
    <>
      <div className="flex justify-center space-x-2">
        <VerificationInput
          length={6}
          onChange={(code) => handleConfirmationCodeChange(code)}
          autoFocus
          classNames={{
            container: "flex justify-center space-x-2",
          }}
        />
      </div>

      {emailConirmationState.error && (
        <div className="text-center text-sm leading-6 text-red-500">
          {emailConirmationState.error}
        </div>
      )}
    </>
  );
};

const ResendConfirmationEmailDiv: React.FC = () => {
  const { emailConirmationState, resendConfirmationEmail } = useAuthState();
  return (
    <>
      <p className="text-center text-sm leading-6 text-gray-500">
        Didn't receive confirmation?{" "}
        <div
          className="cursor-pointer font-semibold text-gray-600 hover:text-gray-500"
          onClick={resendConfirmationEmail}
        >
          Resend Email
        </div>
        {emailConirmationState.resendEmailStatus === "success" && (
          <span className="text-green-500">Email sent successfully</span>
        )}
        {emailConirmationState.resendEmailStatus === "error" && (
          <span className="text-red-500">Error sending email</span>
        )}
      </p>
    </>
  );
};

export default ConfirmEmail;
