import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";

import { APP_BACKEND_URL } from "../lib/utils";
import { IGroupedChatNavigationItem, NavigationItem } from "../types/sidebar";
import { useGlobalState } from "./GlobalState";

interface ISideBarContext {
  navigationList: NavigationItem[];
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addNewChat: (newChatId: string) => void;
  update_navigation_chats: (
    grouped_chats: IGroupedChatNavigationItem[] | undefined,
  ) => NavigationItem[];
}

const initialSideBarContextState: ISideBarContext = {
  navigationList: [],
  sidebarOpen: false,
  addNewChat: () => {},
  update_navigation_chats: () => [],
  setSidebarOpen: () => {},
};

export const SideBarContext = createContext<ISideBarContext>(
  initialSideBarContextState,
);

export const useSideBar = () => React.useContext(SideBarContext);

export const SideBarProvider: React.FC<{
  children: React.ReactNode;
  initialChats: IGroupedChatNavigationItem[];
}> = ({ children, initialChats }) => {
  const { user } = useGlobalState();
  const { chatId } = useParams();

  let initialNavigationList: NavigationItem[] = [
    {
      name: "Chats",
      href: "/chats",
      // icon: ChatIcon,
      current: true,
      children: [],
    },
  ];

  initialNavigationList = initialNavigationList.map((item) => {
    if (item.name === "Chats") {
      item.children = initialChats;
    }
    return item;
  });

  const [navigationList, setNavigationList] = React.useState<NavigationItem[]>(
    initialNavigationList,
  );
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  const addNewChat = React.useCallback((newChatId: string) => {
    const newChat: NavigationItem = {
      name: "New Chat",
      href: `/dashboard/chats/${newChatId}`,
      current: true,
    };

    setNavigationList((prev) => {
      let navigationList = prev;
      let updatedNavigationList: NavigationItem[] = [];
      // look for Today group, if not found create it and add the new chat to it
      const chatMenuIndex = navigationList.findIndex(
        (item) => item.name === "Chats",
      );
      const todayGroupIndex =
        chatMenuIndex !== -1
          ? (
              navigationList[chatMenuIndex]
                .children as IGroupedChatNavigationItem[]
            ).findIndex((group) => group.time_period === "Today")
          : -1;
      if (todayGroupIndex !== -1) {
        const newGroup: IGroupedChatNavigationItem = {
          time_period: "Today",
          chats: [newChat],
        };

        updatedNavigationList = navigationList.map((item) => {
          if (item.name === "Chats") {
            const itemChildren = item.children as IGroupedChatNavigationItem[];
            const doesChatsHaveTodayGroup =
              itemChildren &&
              itemChildren[0] &&
              typeof itemChildren === "object" &&
              "time_period" in itemChildren[0] &&
              itemChildren[0].time_period === "Today";

            if (doesChatsHaveTodayGroup) {
              itemChildren[0].chats.push(newChat);
            } else {
              item.children = [newGroup, ...itemChildren];
            }
          }
          return item;
        });
      } else {
        updatedNavigationList = [...navigationList, newChat];
      }
      return updatedNavigationList;
    });
  }, []);

  const update_navigation_chats = (
    grouped_chats: IGroupedChatNavigationItem[] | undefined,
  ) => {
    if (!grouped_chats) return navigationList;

    const updatedNavigationList = navigationList.map((item) => {
      if (item.name === "Chats") {
        item.children = grouped_chats;
      }
      return item;
    });

    return updatedNavigationList;
  };

  React.useEffect(() => {
    if (!user) return;

    const socket = io(APP_BACKEND_URL, {
      transports: ["websocket"],
      secure:
        process.env.REACT_APP_DEPLOYMENT_ENV === "production" ? true : false,
    });

    function onConnect() {
      socket.emit("userSub", user!.id);
      console.debug("Connected to userSub socket server");
    }

    function onDisconnect() {
      console.debug("Disconnected from userSub server");
    }

    function userNewChatEvent(customData: any) {
      const chatTitle = customData.title;
      const chatPath = `/dashboard${customData.path}`;

      const newNavigateList = navigationList.map((item) => {
        if (item.name === "Chats") {
          const children = item.children as
            | IGroupedChatNavigationItem[]
            | undefined;
          if (
            children?.find(
              (child: IGroupedChatNavigationItem) =>
                child.time_period === "Today",
            )
          ) {
            const todayGroupIndex = (
              item.children as IGroupedChatNavigationItem[]
            ).findIndex((group) => group.time_period === "Today");
            (item.children as IGroupedChatNavigationItem[])[
              todayGroupIndex
            ].chats.unshift({
              name: chatTitle,
              href: chatPath,
              current: true,
            });
          } else {
            item.children = [
              {
                time_period: "Today",
                chats: [
                  {
                    name: chatTitle,
                    href: chatPath,
                    current: true,
                  },
                ],
              },
              ...(item.children as IGroupedChatNavigationItem[]),
            ];
          }
        }
        return item;
      });

      setNavigationList(newNavigateList);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("newChat", userNewChatEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("newChat", userNewChatEvent);
    };
  }, [user]);

  React.useEffect(() => {
    const makePreviesChatNotCurrent = (
      children: IGroupedChatNavigationItem[],
    ) => {
      return children.map((group) => {
        group.chats = group.chats.map((chat) => {
          chat.current = false;
          return chat;
        });
        return group;
      });
    };
    if (!chatId) {
      setNavigationList((prev) => {
        const updatedNavigationList = prev.map((item) => {
          if (item.name === "Chats") {
            const children = item.children as
              | IGroupedChatNavigationItem[]
              | undefined;
            makePreviesChatNotCurrent(children!);
          }
          return item;
        });
        return updatedNavigationList;
      });
    } else {
      setNavigationList((prev) => {
        const updatedNavigationList = prev.map((item) => {
          if (item.name === "Chats") {
            const children = item.children as
              | IGroupedChatNavigationItem[]
              | undefined;
            makePreviesChatNotCurrent(children!);
            if (
              children?.find(
                (child: IGroupedChatNavigationItem) =>
                  child.time_period &&
                  child.chats.find((chat) => chat.href === `chat/${chatId}`),
              )
            ) {
              item.children = children?.map((group) => {
                if (
                  group.time_period &&
                  group.chats.find((chat) => chat.href === `chat/${chatId}`)
                ) {
                  group.chats = group.chats.map((chat) => {
                    if (chat.href === `chat/${chatId}`) {
                      chat.current = true;
                    } else {
                      chat.current = false;
                    }
                    return chat;
                  });
                }

                return group;
              });
            }
          }
          return item;
        });

        return updatedNavigationList;
      });
    }
  }, [chatId]);

  const sharedState: ISideBarContext = {
    navigationList,
    sidebarOpen,
    addNewChat,
    update_navigation_chats,
    setSidebarOpen,
  };

  return (
    <SideBarContext.Provider value={sharedState}>
      {children}
    </SideBarContext.Provider>
  );
};
