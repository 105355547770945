import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../../lib/utils";
import { IGroupedChatNavigationItem } from "../../types/sidebar";
import { Disclosure } from "@headlessui/react";

const mockChats: IGroupedChatNavigationItem[] = [
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
];

export const DesktopSidebarSkeleton: React.FC = () => {
  return (
    <>
      <Disclosure.Panel as="ul" className="mt-1 px-2">
        {mockChats.map((subItem, index) => {
          return (
            <li key={index}>
              <h3 className="text-xs font-semibold uppercase tracking-wider text-gray-400">
                <Skeleton />
              </h3>
              <ul className="mt-1 space-y-1">
                {subItem.chats.map((chat) => (
                  <li key={chat.name}>
                    <div
                      className={classNames(
                        "text-gray-400 hover:bg-gray-800 hover:text-white",
                        "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-400",
                      )}
                    >
                      <Skeleton />
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </Disclosure.Panel>
    </>
  );
};
