import React from "react";

const LoadingSpinner: React.FC<{ height?: string; size?: number }> = ({
  height = "h-full",
  size = 32,
}) => {
  return (
    <div className={`flex ${height} items-center justify-center`}>
      <div className="text-center">
        <div
          className={`h-${size} w-${size} animate-spin rounded-full border-b-2 border-gray-900`}
        ></div>
        <div className="text-gray-900">Loading...</div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
