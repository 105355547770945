import { Session, SupabaseClient, User } from "@supabase/supabase-js";
import React, { createContext } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";

import { APP_BACKEND_URL } from "../lib/utils";
import { supabase } from "../requests/supabase";
import { IPlatformLimits } from "../types/sidebar";
import LoadingSpinner from "../components/ui/Loading/LoadingSpinner";
import { IETFInfo } from "../components/Reports/etf-comparison/ComparisonReport";

export type ChatTab = {
  name: string;
  etf_to_analyze?: number;
  etfs_to_compare?: IETFInfo[];
  holdings_to_compare?: any;
  icon: React.ElementType;
  isCurrent: boolean;
  onClick: () => void;
  chatId: string;
  onDelete: () => void;
};

interface IGlobalStateContext {
  session: Session;
  supabase: SupabaseClient<any, "public", any>;
  user: User | null;
  isLoggedIn: boolean;
  userMessageConsumption: number;
  openTabs: ChatTab[];
  currentView: "chat" | "report-card" | "comparison-card" | "account";
  newChat: string;
  setCurrentView: (
    view: "chat" | "report-card" | "comparison-card" | "account",
  ) => void;
  setNewChat: React.Dispatch<React.SetStateAction<string>>;
  handleTabRemove: (tabName: string) => void;
  setOpenTabs: React.Dispatch<React.SetStateAction<ChatTab[]>>;
  setSession: React.Dispatch<React.SetStateAction<Session | null>>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  signOut: (scope: "global" | "local" | "others") => void;
}

const initialGlobalStateContextState: IGlobalStateContext = {
  session: {} as Session,
  supabase: {} as SupabaseClient<any, "public", any>,
  user: null,
  isLoggedIn: false,
  userMessageConsumption: 0,
  openTabs: [],
  currentView: "chat",
  newChat: "",
  setNewChat: () => {},
  setCurrentView: () => {},
  handleTabRemove: () => {},
  setOpenTabs: () => {},
  setSession: () => {},
  setUser: () => {},
  signOut: () => {},
};

export const GlobalStateContext = createContext<IGlobalStateContext>(
  initialGlobalStateContextState,
);

export const useGlobalState = () => React.useContext(GlobalStateContext);

console.debug("NODE_ENV", process.env.NODE_ENV);

export const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [currentView, setCurrentView] = React.useState<
    "chat" | "report-card" | "comparison-card" | "account"
  >(initialGlobalStateContextState.currentView);

  const [newChat, setNewChat] = React.useState<string>("");

  const { userMessageCount, currentUserLimits } = useLoaderData() as {
    userMessageCount: number;
    currentUserLimits: IPlatformLimits;
  };

  const invertMessageConsumption = (messageCount: number) => {
    const inverse = currentUserLimits.num_messages - messageCount;
    return inverse >= 0 ? inverse : 0;
  };

  const [session, setSession] = React.useState<Session | null>(null);
  const [user, setUser] = React.useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [userMessageConsumption, setUserMessageConsumption] = React.useState(
    invertMessageConsumption(userMessageCount),
  );
  const [openTabs, setOpenTabs] = React.useState<ChatTab[]>([]);

  const handleTabRemove = (tabName: string) => {
    const isCurrentActiveTab = openTabs.find(
      (tab) => tab.name === tabName && tab.isCurrent,
    );

    if (isCurrentActiveTab) {
      setCurrentView("chat");
    }

    const newTabs = openTabs
      .filter((tab) => tab.name !== tabName)
      .map((tab, index) => {
        if (index === 0) {
          return { ...tab, isCurrent: true };
        }
        return { ...tab, isCurrent: false };
      });
    setOpenTabs(newTabs);

    if (newTabs.length < 2) {
      setCurrentView("chat");
    }
  };

  const signOut = async (scope: "global" | "local" | "others") => {
    await supabase.auth.signOut({ scope });
    setSession(null);
    setUser(null);
  };

  React.useEffect(() => {
    setLoading(true);

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        console.debug("no session");
        setSession(null);
        setUser(null);
        setIsLoggedIn(false);
        setLoading(false);
        navigate("/auth/login");
        return;
      }

      setSession(session);
      setUser(session.user);
      setIsLoggedIn(true);
    });

    setLoading(false);

    return () => subscription.unsubscribe();
  }, [navigate]);

  React.useEffect(() => {
    if (!session) return;

    const socket = io(APP_BACKEND_URL, {
      transports: ["websocket"],
      secure:
        process.env.REACT_APP_DEPLOYMENT_ENV === "production" ? true : false,
    });

    function onConnect() {
      socket.emit("userSub", session!.user.id);
      console.debug("Connected to userSub socket server");
    }

    function onDisconnect() {
      console.debug("Disconnected from userSub server");
    }

    function userInfoEvent(customData: any) {
      const messageCount = customData.messageCount;
      setUserMessageConsumption(invertMessageConsumption(messageCount));
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("userInfo", userInfoEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("userInfo", userInfoEvent);
    };
  }, [session]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const sharedState: IGlobalStateContext = {
    session: session || ({} as Session),
    supabase,
    user,
    isLoggedIn,
    userMessageConsumption,
    openTabs,
    currentView,
    newChat,
    setNewChat,
    setCurrentView,
    handleTabRemove,
    setOpenTabs,
    setSession,
    setUser,
    signOut,
  };

  return (
    <GlobalStateContext.Provider value={sharedState}>
      {children}
    </GlobalStateContext.Provider>
  );
};
