import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BellIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { TokenConsumption } from "../../components/sidebar/DarkSidebarWithHeader";
import { SideBarIcon } from "../../components/ui/icons";
import IconTabs from "../../components/ui/Tabs/icon-tabs";
import { useGlobalState } from "../../context/GlobalState";
import { useSideBar } from "../../context/SidebarState";
import { classNames } from "../../lib/utils";

interface ITopBarProps {
  children: React.ReactNode;
}

const TopBar: React.FC<ITopBarProps> = ({ children }) => {
  const { openTabs } = useGlobalState();

  return (
    <>
      <div className="h-full">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          {/* <button
            type="button"
            className="p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button> */}
          <SidebarTrigger />

          {/* Separator */}
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div
            className="grid grow"
            style={{
              gridTemplateColumns: "70% 30%",
            }}
          >
            {/* <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </form> */}
            {openTabs.length > 1 ? <IconTabs tabs={openTabs} /> : <div />}
            <div className="flex items-center justify-end gap-x-4 lg:gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />

              <ProfileDropdown />
            </div>
          </div>
        </div>

        <main className="flex flex-col">{children}</main>
      </div>
    </>
  );
};

export const SidebarTrigger: React.FC = () => {
  const { sidebarOpen, setSidebarOpen } = useSideBar();

  return (
    <>
      {!sidebarOpen && (
        <div className="pr-4 pt-1">
          <button onClick={() => setSidebarOpen(!sidebarOpen)}>
            <SideBarIcon className="h-6 w-auto text-primary-900" />
          </button>
        </div>
      )}
    </>
  );
};

const ProfileDropdown: React.FC = () => {
  const { signOut, user, setCurrentView } = useGlobalState();

  const displayName =
    user?.user_metadata.given_name ||
    user?.user_metadata.first_name ||
    user?.user_metadata.name ||
    "You";

  const userPic =
    user?.user_metadata.picture ||
    `https://ui-avatars.com/api/?name=${user?.user_metadata.first_name}+${user?.user_metadata.last_name}`;

  return (
    <>
      <TokenConsumption />
      <Menu as="div" className="relative">
        <MenuButton className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={userPic}
            alt=""
          />
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {displayName}
            </span>
            <ChevronDownIcon
              className="ml-2 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            <MenuItem>
              {({ active }) => (
                <Link
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900",
                    "cursor-pointer",
                  )}
                  to={"/dashboard/account"}
                >
                  Account
                </Link>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900",
                    "cursor-pointer",
                  )}
                  onClick={() => signOut("local")}
                >
                  Sign out
                </span>
              )}
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
};

export default TopBar;
