import { Disclosure, DisclosurePanel } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useLoaderData } from "react-router-dom";

import { useGlobalState } from "../../context/GlobalState";
import { SideBarProvider, useSideBar } from "../../context/SidebarState";
import withProviders from "../../context/withProviders";
import { classNames } from "../../lib/utils";
import {
  IGroupedChatNavigationItem,
  NavigationItem,
} from "../../types/sidebar";
import { NewChatIcon, SideBarIcon, TokensIcon } from "../ui/icons";
import { WithTooltip } from "../ui/with-tooltip";
import { DesktopSidebarSkeleton } from "./LoadingSkelaton";

interface IDarkSidebarWithHeader {}

const DarkSidebarWithHeader: React.FC<IDarkSidebarWithHeader> = () => {
  const { userChats } = useLoaderData() as {
    userChats: IGroupedChatNavigationItem[];
    userTokens: number;
  };

  return (
    <div className="flex h-screen transition-all duration-300">
      <SideBarProvider initialChats={userChats}>
        {/* <MobileSidebarTrigger /> */}
        <DesktopSidebar />
        {/* <SidebarTrigger /> */}
        <div className="relative flex flex-1 flex-grow flex-col transition-all duration-300 lg:overflow-y-auto lg:overflow-x-hidden">
          <Outlet />
        </div>
      </SideBarProvider>
    </div>
  );
};

// const MobileSidebarTrigger: React.FC = () => {
//   const { sidebarOpen, setSidebarOpen } = useSideBar();

//   return (
//     <Transition.Root show={sidebarOpen} as={Fragment}>
//       <Dialog
//         as="div"
//         className="relative z-50 lg:hidden"
//         onClose={setSidebarOpen}
//       >
//         <Transition.Child
//           as={Fragment}
//           enter="transition-opacity ease-linear duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="transition-opacity ease-linear duration-300"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-primary-100/80" />
//         </Transition.Child>

//         <div className="fixed inset-0 flex">
//           <Transition.Child
//             as={Fragment}
//             enter="transition ease-in-out duration-300 transform"
//             enterFrom="-translate-x-full"
//             enterTo="translate-x-0"
//             leave="transition ease-in-out duration-300 transform"
//             leaveFrom="translate-x-0"
//             leaveTo="-translate-x-full"
//           >
//             <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-in-out duration-300"
//                 enterFrom="opacity-0"
//                 enterTo="opacity-100"
//                 leave="ease-in-out duration-300"
//                 leaveFrom="opacity-100"
//                 leaveTo="opacity-0"
//               >
//                 <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
//                   <button
//                     type="button"
//                     className="-m-2.5 p-2.5"
//                     onClick={() => setSidebarOpen(false)}
//                   >
//                     <span className="sr-only">Close sidebar</span>
//                     <XMarkIcon
//                       className="h-6 w-6 text-white"
//                       aria-hidden="true"
//                     />
//                   </button>
//                 </div>
//               </Transition.Child>

//               <MobileSidebar />
//             </Dialog.Panel>
//           </Transition.Child>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// };

// const MobileSidebar: React.FC = () => {
//   return (
//     <>
//       <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-100 px-6 pb-4 ring-1 ring-white/10">
//         <div className="flex h-16 shrink-0 items-center">
//           <img className="mr-3 h-8 w-auto" src={logo} alt="ChatETF logo" />
//           <h1 className="text-3xl font-bold text-white">ChatETF</h1>
//         </div>
//         <nav className="flex flex-1 flex-col">
//           <ul className="flex flex-1 flex-col gap-y-7">
//             <li>
//               <ul className="-mx-2 space-y-1">
//                 {navigation.map((item) => (
//                   <li key={item.name}>
//                     <a
//                       href={item.href}
//                       className={classNames(
//                         item.current
//                           ? "bg-primary-100 text-white"
//                           : "text-gray-400 hover:bg-primary-100 hover:text-white",
//                         "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
//                       )}
//                     >
//                       <item.icon
//                         className="h-6 w-6 shrink-0 text-white"
//                         aria-hidden="true"
//                       />
//                       {item.name}
//                     </a>
//                   </li>
//                 ))}
//               </ul>
//             </li>
//             <li className="mt-auto">
//               <span className="group -mx-2 flex cursor-not-allowed gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-primary-100 hover:text-white">
//                 <Cog6ToothIcon
//                   className="h-6 w-6 shrink-0"
//                   aria-hidden="true"
//                 />
//                 Settings
//               </span>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </>
//   );
// };

const DesktopSidebar: React.FC = () => {
  const { setCurrentView, setOpenTabs } = useGlobalState();
  const { navigationList, sidebarOpen, setSidebarOpen } = useSideBar();

  const resetChatView = () => {
    setOpenTabs((prev) => {
      return prev.map((tab) => ({
        ...tab,
        isCurrent: false,
      }));
    });
    setCurrentView("chat");
  };

  return (
    <>
      <div
        className={`${sidebarOpen ? "lg:inset-y-0 lg:flex lg:w-72 lg:flex-col" : "w-0 flex-shrink"} flex-shrink-0 bg-primary-900 transition-all duration-300 lg:overflow-y-auto lg:overflow-x-hidden lg:shadow-lg lg:ring-1 lg:ring-white/10`}
      >
        <div
          className={`flex grow flex-col gap-y-5 overflow-y-auto bg-primary-900 px-6 pb-4 ${!sidebarOpen ? "hidden" : ""}`}
        >
          <div className="flex h-16 shrink-0 items-center justify-between">
            <Link to="/dashboard">
              <h1
                className="cursor-pointer text-3xl font-bold text-white"
                onClick={resetChatView}
              >
                ChatETF
              </h1>
            </Link>
            <div className="mt-1 pt-1">
              <button onClick={() => setSidebarOpen(!sidebarOpen)}>
                <SideBarIcon className="h-6 w-auto text-white" />
              </button>
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigationList.map((item, index) => (
                    <li key={index}>
                      {!item.children ? (
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-primary-100 text-white"
                              : "text-gray-400 hover:bg-primary-100 hover:text-white",
                            "group flex gap-x-3 rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-400",
                          )}
                        >
                          {item.icon && (
                            <item.icon
                              className="h-5 w-5 shrink-0"
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </Link>
                      ) : item.name === "Chats" ? (
                        <Disclosure as="div" defaultOpen={true}>
                          {({ open }) => (
                            <>
                              {/* <div
                                className={classNames(
                                  item.current
                                    ? "bg-primary-100 text-white"
                                    : "text-gray-400 hover:bg-primary-100 hover:text-white",
                                  "flex w-full flex-row items-center gap-x-3 rounded-md text-left",
                                )}
                              >
                                <ChatsDropdown item={item} open={open} />
                                <NewChatButton />
                              </div> */}
                              {!navigationList ? (
                                <DesktopSidebarSkeleton />
                              ) : (
                                <DisclosurePanel as="ul" className="mt-1 px-2">
                                  {item.children?.map((subItem, index) => {
                                    if ("time_period" in subItem) {
                                      return (
                                        <li key={index}>
                                          <div className="flex items-center justify-between gap-x-3">
                                            <h3 className="my-3 pl-4 text-xs font-semibold capitalize tracking-wider text-[#658ea9]">
                                              {subItem.time_period}
                                            </h3>
                                            {index === 0 && <NewChatButton />}
                                          </div>
                                          <ul className="mt-1 space-y-1">
                                            {subItem.chats.map(
                                              (chat, index) => (
                                                <li key={index}>
                                                  <Link
                                                    to={`${chat.href}`}
                                                    onClick={resetChatView}
                                                    className={classNames(
                                                      chat.current
                                                        ? "bg-primary-100 text-white"
                                                        : "text-gray-400 hover:bg-primary-100 hover:text-white",
                                                      "block rounded-md px-4 text-sm leading-6 text-gray-400",
                                                    )}
                                                  >
                                                    {chat.name}
                                                  </Link>
                                                </li>
                                              ),
                                            )}
                                          </ul>
                                        </li>
                                      );
                                    }

                                    return (
                                      <li key={index}>
                                        <Disclosure.Button
                                          as="a"
                                          href={subItem.href}
                                          className={classNames(
                                            subItem.current
                                              ? "bg-primary-100 text-white"
                                              : "text-gray-400 hover:bg-primary-100 hover:text-white",
                                            "block rounded-md py-2 pr-2 text-sm leading-6 text-gray-400",
                                          )}
                                        >
                                          {subItem.name}
                                        </Disclosure.Button>
                                      </li>
                                    );
                                  })}
                                  {item.children?.length === 0 && (
                                    <li className="px-8 text-center">
                                      <p className="text-gray-400">
                                        No chats yet...
                                      </p>
                                    </li>
                                  )}
                                </DisclosurePanel>
                              )}
                            </>
                          )}
                        </Disclosure>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="mt-auto">
                {/* <span
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-primary-100 hover:text-white cursor-not-allowed"
                >
                  <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  Settings
                </span> */}
                {/* <ProfileDropdown /> */}
                {/* <TokenConsumption /> */}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export const TokenConsumption: React.FC = () => {
  const hoursUntilUTCMidnight = 24 - new Date().getUTCHours();
  const minutesUntilUTCMidnight = 60 - new Date().getUTCMinutes();
  const tooltipMessage =
    hoursUntilUTCMidnight > 0
      ? `Your message limit will reset in ${hoursUntilUTCMidnight} hours`
      : `Your message limit will reset in ${minutesUntilUTCMidnight} minutes`;
  return (
    <div className="flex flex-col items-center">
      <WithTooltip
        delayDuration={500}
        side="bottom"
        display={
          <div className="w-full bg-white text-black">{tooltipMessage}</div>
        }
        trigger={<TokenConsumptionTooltip />}
      />
    </div>
  );
};

const TokenConsumptionTooltip: React.FC = () => {
  const { userMessageConsumption } = useGlobalState();
  return (
    <div className="flex w-full items-center justify-between gap-x-2 rounded-badge bg-primary-100 px-3 py-1 shadow-inner">
      <span className="text-sm text-white">{userMessageConsumption}</span>
      <TokensIcon className="h-6 w-4 text-white" />
    </div>
  );
};

const ChatsDropdown: React.FC<{ item: NavigationItem; open: boolean }> = ({
  item,
  open,
}) => (
  <Disclosure.Button
    className={classNames(
      item.current
        ? "bg-primary-100 text-white"
        : "text-gray-400 hover:bg-primary-100 hover:text-white",
      "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-400 transition-all duration-300",
    )}
  >
    <ChevronRightIcon
      className={classNames(
        open ? "rotate-90 text-white" : "text-white",
        "h-5 w-5 shrink-0",
      )}
      aria-hidden="true"
    />
    {item.icon && <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />}
    {item.name}
  </Disclosure.Button>
);

const NewChatButton: React.FC = () => (
  <Link
    to={"/dashboard"}
    className={classNames(
      "text-gray-400 hover:text-white",
      "block rounded-md py-2 text-sm leading-6 text-gray-400",
    )}
  >
    <NewChatIcon
      className={classNames(
        "h-5 w-5 shrink-0 cursor-pointer text-white hover:scale-110 hover:transform",
      )}
      aria-hidden="true"
    />
  </Link>
);
export default withProviders(DarkSidebarWithHeader);
