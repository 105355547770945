import React, { createContext } from "react";
import { IAnalyzeETfData, ICompareETFData } from "../types/customData";

interface DashboardContext {
  currentViewData: IAnalyzeETfData | ICompareETFData | null;
  setCurrentViewData: (data: any) => void;
}

export const initialDashboardContextState: DashboardContext = {
  currentViewData: null,
  setCurrentViewData: () => {},
};

export const DashboardContext = createContext<DashboardContext>(
  initialDashboardContextState,
);

export const useDashboard = () => React.useContext(DashboardContext);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentViewData, setCurrentViewData] = React.useState<any>(null);

  const sharedState: DashboardContext = {
    currentViewData,
    setCurrentViewData,
  };

  return (
    <DashboardContext.Provider value={sharedState}>
      {children}
    </DashboardContext.Provider>
  );
};
