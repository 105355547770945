import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import DarkSidebarWithHeader from "../components/sidebar/DarkSidebarWithHeader";
import AdminSignUp from "../pages/authentication/AdminSignUp";
import Login from "../pages/authentication/Login";
import PageNotFoundError from "../pages/errors/PageNotFoundError";
import Home from "../pages/home";
import {
  checkIfLogginIn,
  dashboardLoader,
  generateNewChatId,
  getChatMessages,
} from "./loaders";
import UserSignUp from "../pages/authentication/UserSignUp";
import ConfirmEmail from "../pages/authentication/ConfirmEmail";
import AuthPage from "../pages/authentication";
import ResetPassword from "../pages/authentication/ResetPassword";
import ResetPasswordForm from "../pages/authentication/ResetPassword/reset-form";
import TermsConditionsPage from "../pages/public/TermsConditions";
import SocialLoginCallback from "../pages/authentication/UserSignUp/SocialLoginCallback";
import Dashboard from "../pages/home/dashboard";
import LoadingSpinner from "../components/ui/Loading/LoadingSpinner";
import AccountPage from "../pages/Account";
// import ETFChat from "../pages/chat/ETFChat";
const ETFChat = React.lazy(() => import("../pages/chat/ETFChat"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <PageNotFoundError />,
    loader: checkIfLogginIn,
  },
  {
    path: "terms",
    element: <TermsConditionsPage />,
  },
  {
    path: "auth",
    element: <AuthPage />,
    loader: checkIfLogginIn,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "user-signup",
        element: <UserSignUp />,
      },
      {
        path: "admin-signup",
        element: <AdminSignUp />,
      },
      {
        path: "confirm-email",
        element: <ConfirmEmail />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password-form",
        element: <ResetPasswordForm />,
      },
      {
        path: "callback",
        element: <SocialLoginCallback />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DarkSidebarWithHeader />,
    errorElement: <PageNotFoundError />,
    loader: dashboardLoader,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "account",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <AccountPage />
          </Suspense>
        ),
      },
      {
        path: "chat",
        element: <ETFChat />,
      },
      {
        path: "chat/new",
        // element: <ETFChat />,
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <ETFChat />
          </Suspense>
        ),
        loader: generateNewChatId,
      },
      {
        path: "chat/:chatId",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <ETFChat />
          </Suspense>
        ),
        children: [
          {
            path: "etf-report",
            element: (
              <>
                <h1>Coool beans!</h1>
              </>
            ),
          },
        ],
      },
    ],
  },
]);
