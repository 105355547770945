import { useState } from "react";

import Login from "../authentication/Login";
import UserSignUp from "../authentication/UserSignUp";
import { TypeAnimation } from "react-type-animation";
import { AuthStateProvider } from "../../context/AuthState";
import { ChatEtfLogo } from "../../components/ui/icons";

const Hero: React.FC = () => (
  <>
    {/* <svg
      className="inset-0 -z-10 w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
          width={200}
          height={200}
          x="50%"
          y={-1}
          patternUnits="userSpaceOnUse"
        >
          <path d="M.5 200V.5H200" fill="none" />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        strokeWidth={0}
        fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
      />
    </svg> */}

    <div className="mx-auto grid max-w-2xl lg:mx-0 lg:max-w-xl">
      {/* <h1 className="mt-10 text-center text-6xl font-extrabold tracking-tight text-gray-900 sm:text-6xl">
        ChatETF
      </h1> */}
      <div className="min-h-[328px]">
        <h1 className="mt-5 text-2xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
          <TypeAnimation
            sequence={[
              "Supercharge your ETF research",
              5000,
              "Analyze ETFs with ease",
              5000,
              "Get insights into ETF holdings",
              5000,
              "Screen, analyze, compare ETFs, and more",
              5000,
              "Just ask ChatETF",
              5000,
            ]}
            wrapper="span"
            speed={50}
            style={{
              display: "inline-block",
              lineHeight: "6rem",
            }}
            repeat={Infinity}
          />
        </h1>
      </div>
      <p className="text-lg leading-8 text-gray-600">
        Screen, analyze, and compare ETFs with ease. Get insights into ETF
        holdings, performance, and more.
      </p>
    </div>
  </>
);

export default function Home() {
  const [authView, setAuthView] = useState<"login" | "signup">("signup");
  return (
    <div className="container isolate mx-auto my-auto bg-white px-0 md:h-full">
      <div className="lg:grid lg:h-full lg:grid-cols-2">
        <div className="mx-auto mt-16 flex w-full max-w-2xl sm:mt-24 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none">
          <div className="flex max-w-3xl items-center sm:max-w-5xl lg:max-w-none">
            <div>
              <ChatEtfLogo className="my-0 w-10/12" />
              <Hero />
            </div>
            {/* <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                src={AppScreenshot}
                alt="App screenshot"
                width={2432}
                height={1442}
                className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div> */}
          </div>
        </div>
        <AuthStateProvider>
          {authView === "login" ? (
            <Login size="sm" switchView={() => setAuthView("signup")} />
          ) : (
            <UserSignUp size="sm" switchView={() => setAuthView("login")} />
          )}
        </AuthStateProvider>
      </div>
    </div>
  );
}
