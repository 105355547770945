import React from "react";
import { Link } from "react-router-dom";

import AiBrainIcon from "../../../assets/AiBrainIcon.svg";
import { useAuthState } from "../../../context/AuthState";

const ResetPassword: React.FC = () => {
  const {
    resetPasswordState,
    setResetPasswordState,
    handleResetPasswordRequest,
  } = useAuthState();

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              // className="mx-auto h-10 w-auto"
              className="mx-auto h-10 w-auto rounded-md bg-primary-100"
              src={AiBrainIcon}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset Your Password
            </h2>
          </div>
          <form className="space-y-6" onSubmit={handleResetPasswordRequest}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={resetPasswordState.email}
                  onChange={(e) =>
                    setResetPasswordState({
                      ...resetPasswordState,
                      email: e.target.value,
                    })
                  }
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                />
              </div>
            </div>

            {resetPasswordState.errors.length > 0 && (
              <div className="space-y-6">
                {resetPasswordState.errors.map((error, index) => (
                  <div
                    key={index}
                    className="text-sm font-semibold text-red-500"
                  >
                    {error}
                  </div>
                ))}
              </div>
            )}

            {resetPasswordState.resetPasswordStatus === "success" && (
              <div className="text-sm font-semibold text-green-500">
                Reset link sent successfully
              </div>
            )}

            {resetPasswordState.isLoading ? (
              <div className="flex items-center justify-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : (
              <>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    Send Reset Request
                  </button>
                </div>
              </>
            )}
          </form>

          <p className="text-center text-sm leading-6 text-gray-500">
            <Link
              to="/auth/login"
              className="font-semibold text-gray-600 hover:text-gray-500"
            >
              Back to login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
