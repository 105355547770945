import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

import { ChatTab } from "../../../context/GlobalState";
import { classNames } from "../../../lib/utils";

interface TabsProps {
  tabs: ChatTab[];
}

const IconTabs: React.FC<TabsProps> = ({ tabs }) => {
  return (
    <div className="flex items-center">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.isCurrent)?.name}
          className="focus:border-pri mary-500 block w-full rounded-md border-gray-300 focus:ring-primary-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav aria-label="Tabs" className="-mb-px flex">
            {tabs.map((tab) => {
              const isChat = tab.name.toLowerCase() === "chat";
              return (
                <div
                  key={tab.name}
                  aria-current={tab.isCurrent ? "page" : undefined}
                  className={classNames(
                    tab.isCurrent
                      ? "bg-primary-100 text-white"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    isChat && tab.isCurrent ? "hidden" : "",
                    "flex cursor-pointer items-center rounded-badge px-3 py-1 text-sm",
                  )}
                >
                  <div
                    onClick={tab.onClick}
                    className={classNames(
                      tab.isCurrent
                        ? "bg-primary-100 text-white"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      isChat && tab.isCurrent ? "hidden" : "",
                      "group inline-flex cursor-pointer items-center text-sm font-medium",
                    )}
                  >
                    <tab.icon
                      aria-hidden="true"
                      className={classNames(
                        tab.isCurrent
                          ? "text-white"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-2 h-4 w-5",
                      )}
                    />
                    <div className="flex items-center">
                      <>{isChat ? "Back to chat" : tab.name}</>
                    </div>
                    {isChat && (
                      <div
                        className="mx-4 hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  {!isChat ? (
                    // x icon to remove tab
                    <button
                      type="button"
                      className="-m-2.5 ml-1 p-2.5 text-white hover:text-gray-300"
                      onClick={tab.onDelete}
                    >
                      <span className="sr-only">Remove tab</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  ) : null}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default IconTabs;
