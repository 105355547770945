import { Message } from 'ai';
import axios from 'axios';

import { APP_BACKEND_URL } from '../lib/utils';
import { IChatFeedbackSubmission, RelatedDocsItem } from '../types/chat';
import { IEODHoldingData, IETFTiData, INewsData, IPerformanceData, ISimilarEtfData } from '../types/customData';
import { IGroupedChatNavigationItem, IPlatformLimits } from '../types/sidebar';

const baseUrl = APP_BACKEND_URL + "/api";

export async function getChats(
  userId: string,
  access_token: string,
): Promise<IGroupedChatNavigationItem[]> {
  try {
    const { data } = await axios.get(`${baseUrl}/chats/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    throw error;
  }
}

export async function getChatById(
  chatId: string | undefined,
  access_token: string,
): Promise<Message[]> {
  try {
    const { data } = await axios.get(`${baseUrl}/chat/${chatId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export async function fetchUserTokenConsumption(
  userId: string,
  access_token: string,
): Promise<number> {
  try {
    const { data } = await axios.get(`${baseUrl}/token-consumption/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data.token_count;
  } catch (error) {
    console.error("Error fetching token usage: ", error);
    throw error;
  }
}

export async function fetchUserMessageConsumption(
  userId: string,
  access_token: string,
): Promise<number> {
  try {
    const { data } = await axios.get(
      `${baseUrl}/messages-consumption/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
    return data.message_count;
  } catch (error) {
    console.error("Error fetching token usage: ", error);
    throw error;
  }
}

export async function fetchUserLimits(
  userId: string,
  access_token: string,
): Promise<IPlatformLimits> {
  try {
    const { data } = await axios.get(
      `${baseUrl}/platform-limits/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
    return data.currentUserLimits
  } catch (error) {
    console.error("Error fetching token usage: ", error);
    throw error;
  }
}


export async function sendChatFeedback(
  chatId: string,
  feedback: IChatFeedbackSubmission,
  access_token: string,
): Promise<void> {
  try {
    await axios.post(
      `${baseUrl}/chat/${chatId}/feedback`,
      { feedback },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  } catch (error) {
    console.error("Error sending feedback: ", error);
    throw error;
  }
}

export async function getEtfDetails(
  share_id: number | undefined,
  access_token: string,
): Promise<IETFTiData> {
  try {
    const { data } = await axios.get(`${baseUrl}/reports/${share_id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export async function getEtfPerformance(
  etf_ticker: string | undefined,
  access_token: string,
): Promise<IPerformanceData> {
  try {
    const { data } = await axios.get<IPerformanceData>(`${baseUrl}/reports/performance/${etf_ticker}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export async function getEtfDocuments(
  etf_ticker: string | undefined,
  access_token: string,
): Promise<RelatedDocsItem[] | null> {
  try {
    const { data } = await axios.get<RelatedDocsItem[] | null>(`${baseUrl}/reports/related-docs/${etf_ticker}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export async function getSimilarEtfs(
  etf_isin: string,
  access_token: string,
): Promise<ISimilarEtfData[] | null> {
  try {
    const { data } = await axios.get<ISimilarEtfData[] | null>(`${baseUrl}/reports/similar-etfs/${etf_isin}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export async function getEtfNews(
  etf_ticker: string,
  access_token: string,
): Promise<INewsData[]> {
  try {
    const { data } = await axios.get<INewsData[]>(`${baseUrl}/reports/news/${etf_ticker}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export const getEtfHoldings = async (
  etf_ticker: string,
  access_token: string,
): Promise<IEODHoldingData> => {
  try {
    const { data } = await axios.get<IEODHoldingData>(`${baseUrl}/reports/holdings/${etf_ticker}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    //TODO Handle error
    console.error("Error fetching chat by id: ", error);
    throw error;
  }
}

export const deleteUserAccount = async (
  userId: string | undefined,
  access_token: string,
): Promise<boolean> => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }
    const response = await axios.delete(`${APP_BACKEND_URL}/accounts/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to delete user account");
    }

    return response.data;
  } catch (error) {
    //TODO Handle error
    console.error("Error deleting user account: ", error);
    throw error;
  }
}