import React, { ComponentType } from "react";
import { AuthStateProvider } from "./AuthState";
import { GlobalStateProvider } from "./GlobalState";
import { Toaster } from "react-hot-toast";

const withProviders = (WrappedComponent: ComponentType<any>) => {
  return (props: any) => (
    <GlobalStateProvider>
      <WrappedComponent {...props} />
    </GlobalStateProvider>
  );
};

export default withProviders;

export const withAuthProvider = (WrappedComponent: ComponentType<any>) => {
  return (props: any) => (
    <AuthStateProvider>
      <Toaster />
      <WrappedComponent {...props} />
    </AuthStateProvider>
  );
};
