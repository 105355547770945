import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";

import CatchAllErrorPage from "./pages/errors/CatchAllErrorPage";
import reportWebVitals from "./reportWebVitals";
import { router } from "./routes";
import { PrismicProvider } from "@prismicio/react";
import { client as PrismicClient } from "./prismic";
import { logError } from "./lib/utils";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<CatchAllErrorPage />} onError={logError}>
      <PrismicProvider client={PrismicClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PrismicProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
