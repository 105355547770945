import React, { ReactNode } from "react";

import { DashboardProvider } from "../../context/DashboardContext";
import { useGlobalState } from "../../context/GlobalState";
import TopBar from "../chat/TopBar";
import { useSideBar } from "../../context/SidebarState";

type DashboardLayoutProps = {
  children: ReactNode;
};

const DashboardLayoutWrapper: React.FC<DashboardLayoutProps> = ({
  children,
}) => {
  return (
    <DashboardProvider>
      <DashboardLayout>{children}</DashboardLayout>
    </DashboardProvider>
  );
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <>
      <DefaultChatLayout>{children}</DefaultChatLayout>
    </>
  );
};

const DefaultChatLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const { currentView } = useGlobalState();
  const { sidebarOpen } = useSideBar();

  return (
    <div>
      <TopBar>
        {currentView == "chat" ? (
          <div className="mx-auto flex w-full max-w-7xl items-start justify-center gap-x-8 px-4 py-2 sm:px-6 lg:px-8">
            <aside
              className={`sticky top-8 hidden w-${sidebarOpen ? "0" : "0"} shrink-0 lg:block`}
            >
              {/* <img src={ad} alt="Real Estate" /> */}
              {/* Real Estate */}
            </aside>

            <main className="min-w-[45vw] md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
              {children}
            </main>
            <aside className={`sticky top-8 hidden w-0 shrink-0 xl:block`}>
              {/* Real Estate */}
            </aside>
          </div>
        ) : (
          children
        )}
      </TopBar>
    </div>
  );
};

export default DashboardLayoutWrapper;
