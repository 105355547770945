import React from "react";
import { PrismicRichText, useFirstPrismicDocument } from "@prismicio/react";

import { Link } from "react-router-dom";

import AiBrainIcon from "../../assets/AiBrainIcon.svg";

const TermsConditionsPage: React.FC = () => {
  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-xl space-y-10">
          <div>
            <Link className="flex hover:cursor-pointer hover:font-bold" to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-4 size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              Home
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <img
            className="mr-6 h-10 w-auto rounded-md bg-primary-100"
            src={AiBrainIcon}
            alt="Your Company"
          />
          <h1 className="text-4xl font-bold text-gray-900">
            Terms and Conditions
          </h1>
        </div>
        <TermsConditions />
      </div>
    </>
  );
};

export const TermsConditions: React.FC = () => {
  const [document] = useFirstPrismicDocument();

  return (
    <>
      {document && (
        <>
          <PrismicRichText field={document.data.body} />
        </>
      )}
    </>
  );
};

export default TermsConditionsPage;
