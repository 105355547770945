import React from "react";
import { Link } from "react-router-dom";

import AiBrainIcon from "../../../assets/AiBrainIcon.svg";
import { useAuthState } from "../../../context/AuthState";
import { LinkedInLoginButton } from "react-social-login-buttons";
import {
  HideHiddenInputIcon,
  ShowHiddenInputIcon,
} from "../../../components/ui/icons";
import { hideBtnStyle } from "../UserSignUp";

interface LoginProps {
  size?: "sm" | "lg";
  switchView?: () => void;
}

const Login: React.FC<LoginProps> = ({ size = "lg", switchView }) => {
  const { userLoginForm, setUserLoginForm, handleSignInSubmit } =
    useAuthState();

  return (
    <>
      <div
        className={`flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8 ${size === "lg" ? "my-auto" : ""}`}
      >
        <div
          className={`${size === "lg" ? "w-full max-w-sm space-y-10" : "w-[33rem] space-y-5 overflow-hidden rounded p-10 shadow-lg"}`}
        >
          <div>
            {size === "lg" && (
              <img
                // className="mx-auto h-10 w-auto"
                className="mx-auto h-10 w-auto rounded-md bg-primary-100"
                src={AiBrainIcon}
                alt="Your Company"
              />
            )}
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form className="space-y-6" onSubmit={handleSignInSubmit}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userLoginForm.email}
                  onChange={(e) =>
                    setUserLoginForm({
                      ...userLoginForm,
                      email: e.target.value,
                    })
                  }
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="flex">
                  <input
                    id="password"
                    name="password"
                    type={
                      userLoginForm.password.showPassword ? "text" : "password"
                    }
                    autoComplete="current-password"
                    value={userLoginForm.password.value}
                    onChange={(e) =>
                      setUserLoginForm({
                        ...userLoginForm,
                        password: {
                          ...userLoginForm.password,
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    placeholder="Password"
                  />
                  <button
                    className={hideBtnStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      setUserLoginForm({
                        ...userLoginForm,
                        password: {
                          ...userLoginForm.password,
                          showPassword: !userLoginForm.password.showPassword,
                        },
                      });
                    }}
                  >
                    {userLoginForm.password.showPassword ? (
                      <HideHiddenInputIcon />
                    ) : (
                      <ShowHiddenInputIcon />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {userLoginForm.errors.length > 0 && (
              <div className="space-y-6">
                {userLoginForm.errors.map((error, index) => (
                  <div
                    key={index}
                    className="text-sm font-semibold text-red-500"
                  >
                    {error}
                  </div>
                ))}
              </div>
            )}

            {userLoginForm.isLoading ? (
              <div className="flex items-center justify-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <Link
                      to="/auth/reset-password"
                      className="font-semibold text-gray-600 hover:text-gray-500"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    Sign in
                  </button>
                </div>
              </>
            )}
          </form>

          <p className="text-center text-sm leading-6 text-gray-500">
            Not a member?{" "}
            {!switchView ? (
              <Link
                to="/auth/user-signup"
                className="font-semibold text-gray-600 hover:text-gray-500"
              >
                Sign Up
              </Link>
            ) : (
              <button
                onClick={switchView}
                className="font-semibold text-gray-600 hover:text-gray-500"
              >
                Sign Up
              </button>
            )}
          </p>

          <SocialAuthDiv />
        </div>
      </div>
    </>
  );
};

export const SocialAuthDiv: React.FC = () => {
  const { authenticateWithSocialProvider } = useAuthState();
  return (
    <div className="mt-10">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center text-sm font-medium leading-6">
          <span className="bg-white px-6 text-gray-900">Or continue with</span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1">
        <LinkedInLoginButton
          align="center"
          style={{ height: "2.5rem" }}
          onClick={() => authenticateWithSocialProvider("linkedin_oidc")}
        />
      </div>
    </div>
  );
};

export default Login;
