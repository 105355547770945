import * as React from "react";
import AiBrainIcon from "../../../assets/AiBrainIcon.svg";
import { AcceptTermsAndConditions, InvestorTypeSelection } from ".";
import { IInvestorType } from "../../../types/authTypes";
import { useAuthState } from "../../../context/AuthState";

const SocialLoginCallback: React.FunctionComponent = () => {
  const { socialCallbackState, setSocialCallbackState, updateUserMetadata } =
    useAuthState();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateUserMetadata();
  };

  const onChangeHandler = (value: IInvestorType) => {
    setSocialCallbackState({ ...socialCallbackState, investorType: value });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              // className="mx-auto h-10 w-auto"
              className="mx-auto h-10 w-auto rounded-md bg-primary-100"
              src={AiBrainIcon}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Continue Your Account Setup
            </h2>
          </div>
          <form className="space-y-6" onSubmit={handleFormSubmit}>
            <InvestorTypeSelection onChangeHandler={onChangeHandler} />

            {socialCallbackState.investorType && <AcceptTermsAndConditions />}

            {socialCallbackState.isLoading ? (
              <div className="flex items-center justify-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : (
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  Create Account
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default SocialLoginCallback;
