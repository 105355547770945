import { Textarea } from "@headlessui/react";
import { nanoid } from "ai";
import clsx from "clsx";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { ChatEtfLogo, SendIcon } from "../../components/ui/icons";
import DashboardLayout from "../layouts/DashboardLayout";
import { useGlobalState } from "../../context/GlobalState";

const examples = [
  "Who is the issuer of VTI?",
  "Compare LIT and BATT.",
  "What's the expense ratio of QQQ?",
  "How many holdings does ARKK have?",
  "What are the top 10 holdings of XLF?",
  "What is the total weight of VOO's top 10 holdings?",
  "What is the current price of KWEB?",
  "What is the performance of SPY ETF year-to-date.",
];

const getFourUniqueRandomExamples = () => {
  const examplesCopy = [...examples];
  const randomExamples = [];
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * examplesCopy.length);
    const randomExample = examplesCopy[randomIndex];
    randomExamples.push(randomExample);
    examplesCopy.splice(randomIndex, 1);
    if (examplesCopy.length === 0) {
      break;
    }
  }
  return randomExamples;
};

const randomeExamples = getFourUniqueRandomExamples();

const Dashboard: React.FunctionComponent = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();
  const { setNewChat } = useGlobalState();

  const [input, setInput] = React.useState<string>("");
  const [isLoading] = React.useState<boolean>(false);
  const disabled = isLoading || input.length === 0;

  const setInputAndNavigate = (input: string) => {
    const chatId = nanoid();
    setNewChat(input);
    navigate(`/dashboard/chat/${chatId}`);
  };

  const handdleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.debug("Form submitted");
    setInputAndNavigate(input);
  };

  const handleClick = (input: string) => {
    setInput(input);
    setInputAndNavigate(input);
  };

  return (
    <DashboardLayout>
      <div className="relative flex h-[90vh] w-[50vw] flex-col items-center justify-center rounded-md">
        {/* <WelcomeChat inputRef={inputRef} setInput={setInput} /> */}
        <div className="my-4 flex w-96 flex-col items-center">
          <ChatEtfLogo className="w-10/12" />
          <h1 className="text-md text-center text-gray-400 dark:text-white">
            Chat your way to your next ETF investment
          </h1>
        </div>
        <div className="max-w-screen-md">
          <div className="relative top-2 flex w-[100%] flex-col items-center space-y-3 pb-0 sm:px-0">
            <form
              ref={formRef}
              onSubmit={handdleSubmit}
              className="relative w-full rounded-sm pb-2 pt-3 sm:pb-3 sm:pt-4"
            >
              <Textarea
                ref={inputRef}
                style={{
                  resize: "none",
                  borderRadius: "29px",
                  height: 50,
                  paddingTop: "0.75rem",
                  paddingLeft: "1rem",
                }}
                tabIndex={0}
                required
                rows={1}
                autoFocus
                placeholder="Send a message"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    formRef.current?.requestSubmit();
                    e.preventDefault();
                  }
                }}
                spellCheck={false}
                className="focus:border-primary-200 w-full border-gray-200 pr-10 text-black focus:ring-1 focus:ring-primary-100 dark:bg-gray-300"
                disabled={isLoading}
              />
              <button
                className={clsx(
                  "absolute inset-y-0 right-3 my-auto mb-[1.75rem] flex h-8 w-8 items-center justify-center rounded-full transition-all",
                  disabled
                    ? "cursor-not-allowed bg-white dark:bg-gray-300"
                    : "bg-primary-100 hover:bg-gray-600",
                )}
                disabled={disabled}
              >
                <SendIcon
                  className={clsx(
                    "h-4 w-4",
                    disabled ? "text-gray-300" : "text-white",
                  )}
                />
              </button>
            </form>
          </div>
          <div className="grid grid-cols-2 gap-2 py-2">
            {randomeExamples.map((example, i) => (
              <button
                key={i}
                className="rounded-md border border-gray-200 bg-white px-5 py-3 text-left text-xs text-gray-500 transition-all duration-75 hover:border-primary-100 hover:text-gray-700 active:bg-gray-50"
                onClick={() => {
                  if (inputRef) {
                    inputRef.current?.focus();
                    setInput(example);
                    handleClick(example);
                  }
                }}
              >
                {example}
              </button>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
